import type { AppProps } from "next/app"
import { DefaultSeo } from "next-seo"
import "../src/styles/styles.scss"

import SEO from "../next-seo.config"
import Head from "next/head"

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-186337133-3"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-186337133-3');
        `,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','seconddataLayer','GTM-P9SFB3Z');`,
          }}
        ></script>

        <meta name="google-site-verification" content="96IcMZ1SHpwawNtzLvsbLEzn2dCBfts3F3x5tFfUKG0" />
        <meta name="google-site-verification" content="PHtthCnkqH4Oqb2804hPazTPGHxHAGtMkfRfj5FK-ms" />
      </Head>

      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P9SFB3Z" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
      </noscript>
      <DefaultSeo title="Ruby" titleTemplate="%s | Ruby" {...SEO} />
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
